$nav_pills_radius: 				4px;
$nav_pills_link_border:			1px solid $color-text !default;

.nav_pills {

	.nav_menu > .menu_item > .menu_link {
		border-radius: $nav_pills_radius;
	}

	.nav_menu > .open > .menu_link {
		border-radius: $nav_pills_radius $nav_pills_radius 0 0;
	}

/* 	&.nav--primary {
		.menu_link {
			background-color: theme(primary, base); color: $white;
			&:hover { background-color: theme(primary, light) }
		}
		.open > .menu_link { background-color: theme(primary, base); }
		.dropdown_menu { background-color: theme(primary, base); }
	}

	&.nav--secondary {
		.menu_link {
			background-color: theme(secondary, base); color: $white;
			&:hover { background-color: theme(secondary, light) }
		}
		.open > .menu_link { background-color: theme(secondary, base); }
		.dropdown_menu { background-color: theme(secondary, base); }
	}

	&.nav--highlight {
		.menu_link {
			background-color: theme(highlight, base); color: $white;
			&:hover { background-color: theme(highlight, light) }
		}
		.open > .menu_link { background-color: theme(highlight, base); }
		.dropdown_menu { background-color: theme(highlight, base); }
	}

	&.nav--accent {
		.menu_link {
			background-color: theme(accent, base); color: $white;
			&:hover { background-color: theme(accent, light) }
		}
		.open > .menu_link { background-color: theme(accent, base); }
		.dropdown_menu { background-color: theme(accent, base); }
	} */

}

