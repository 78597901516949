$banner-bg: 			$white !default;
$banner-color: 			$color-text;

.banner {
	padding: 1em 0;
	border-bottom: 5px solid $color-secondary;
	box-shadow: 0 0.02em 0.75em 0 rgba(0, 0, 0, 0.5);
	background-color: $banner-bg;
	background: linear-gradient(to bottom, $gray-300, $white 50%);
	color: $banner-color;
}

.title_navigation {
	display: none;

	.menu_item {
		display: block;
		float: left;
		width: percentage(1/6);
		padding: 0 0.125em;

		&.active {
			.menu_link {
				background-color: transparent;
				color: $color-secondary;
			}
		}

	}

		.menu_link {
			padding: 15px 5px;
			font-size: 14px;
			font-weight: 600;
			line-height: 1em;
			letter-spacing: -0.0325em;
			text-align: center;
			text-transform: uppercase;
			border-radius: 3px;
			background-color: $color-primary;
			color: $white;
			&:hover {
				background-color: $color-secondary;
			}
		}


	@include media-breakpoint-up(md) {
		display: block;
		padding-top: 1em;
	}

	@include media-breakpoint-only(md) {
		.menu_item {
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(5) {
				.menu_link {
					padding: 22px 5px;
				}
			}
		}
	}

}