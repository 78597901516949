.title_contact {

	padding-top: 20px;
	text-align: center;

	.call {
		margin-bottom: 0;
		font-weight: 800;
		letter-spacing: -0.0375em;
		line-height: 1em;
		text-transform: uppercase;
		small {
			display: block;
			font-size: 16px;
		}
	}

	.phone {
		font-size: 28px;
	}

	@include media-breakpoint-up(md) {
		padding-top: 0;
		text-align: right;
	}

}