// ===========================================
// BOOTSTRAP'S GRID
// ===========================================

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);
@include _assert-ascending($container-max-widths, "$container-max-widths");

.container {
	@include make-container();
	@include make-container-max-widths();
}

.container-fluid {
	width: 100%;
	@include make-container();
}

.row {
	@include make-row();
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
	> .wrap {
		padding-right: 0;
		padding-left: 0;
	}
}


// ===========================================
// BACE GRID
// ===========================================

html {
	@include prefix('box-sizing', 'border-box', webkit moz ms o);
    -ms-overflow-style: scrollbar;
}

	*,
	*:before,
	*:after {

		@include prefix('box-sizing', 'inherit', webkit moz ms o)
		
	}

	.block { padding: 20px 0; }

	.wrap { @include make-col-ready() }

	// ==================================================
	// BLOCKS
	// ==================================================

	.banner {
		position: relative;
		z-index: 99000;
		@include media-breakpoint-up(md) {
			.row { align-items: center; }
			.title_branding { @include make-col(6); }
			.title_contact { @include make-col(6); }
		}
	}

	.block_jumbo {
		position: relative;
		z-index: 70000;
		@include media-breakpoint-up(lg) {
			.row {
				-ms-flex-align: center;
				-webkit-align-items: center;
				-webkit-box-align: center;
				align-items: center;				
			}
			.jumbo_marketing { @include make-col(8); }
			.jumbo_conversion { @include make-col(4); }
		}
	}

	.content {
		@include media-breakpoint-up(lg) {
			.main { @include make-col(8) }
			.sidebar { @include make-col(4) }
		}
	}

